import './App.css';
import imgLogo from '../src/assets/Trusty_logo_sidebar.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import axios from 'axios';

function App() {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://welcome.trustymoney.in/', { email })
      .then()
      .catch(err => {
        if (err?.response?.data) { alert(err?.response?.data?.message) }
      });
    alert("Thank you for joining the waitlist");
    setEmail("");
  }

  return (
    <div className="App">
      <div className='background_img'></div>
      <div className='container'>
        <img src={imgLogo} className="logo" alt='trusty logo'></img>
        <p className='content'>Redefining Cross-Border Transactions: Simple, Fast, Secure!</p>
        <p className='content_1'>Trusty is a new “Fintech” company, that facilitates cross-border transaction management. In addition to processing cross border payments for its customers, Trusty also provides reconciliation and transaction management services.</p>
        <h1 className='heading'>Our website is <span className='Bold'>Coming Soon</span>, follow us for update now!</h1>

        <form onSubmit={handleSubmit}>
          <div className='div2'>
            <input 
              type='email'
              placeholder="Enter your Email address" 
              className='email_input' 
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <button type='submit' className='submitBtn'> Join Waitlist </button>
          </div>  
        </form>
        <p className='Tag_below'>And don't worry, we hate spam too! You can unsubscribe at any time</p>
        <div className='Tag_below'>
          <FontAwesomeIcon icon={faMapMarkerAlt} className='emailicon'/> 1207, Vikrant Tower, 4-Rajendra Place, New Delhi - 110008   
          
          <a href="mailto:info@trustymoney.in" className='emailLink'>
            <FontAwesomeIcon icon={faEnvelope} className='emailicon'/> info@trustymoney.in
          </a>
        </div>
        <div className="copyright" id="copyright">Copyright &copy; 2024 Tushti Technologies Pvt. Ltd.</div>
      </div>
    </div>
  );
}

export default App;
